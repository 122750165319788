import SignaturePad from 'signature_pad';

document.addEventListener('DOMContentLoaded', () => {
  const canvas = document.querySelector('canvas');
  if (!canvas) {
    return;
  }
  const signaturePad = new SignaturePad(canvas);

  // Vous pouvez ajouter ici d'autres fonctionnalités, comme la gestion des événements 'beginStroke', 'endStroke', etc.
});